import * as React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import ListGalleries from "./Components/ListGalleries";
import CreateGallery from "./Components/CreateGallery";
import EditGallery from "./Components/EditGallery";

import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <ListGalleries />,
    errorElement: <ErrorPage />,
  },
  {
    path: "create",
    element: <CreateGallery />,
  },
  {
    path: "edit/:id",
    element: <EditGallery />,
  },
]);

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isInGalleryMode, setIsInGalleryMode] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  React.useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/3d-carousel/_functions", // Replace with your API base URL
      applicationID: "9eaa8166-257d-4cfd-8457-a3f520f9fc73", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    const token = new URLSearchParams(window.location.search).get("instance")
    localStorage.setItem("token", token);
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!isInGalleryMode) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Card>
          <MarketingPageLayout
            removeImageHorizontalPadding
            removeImageVerticalPadding
            content={
              <Box height="840px" verticalAlign="middle">
                <MarketingPageLayoutContent
                  title="3D"
                  content="To use this widget, go to Editor > Add Panel (+) > App Widgets > 3D"
                  actions={
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        as="a"
                        href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                        target="_blank"
                      >
                        Edit Site
                      </Button>
                      <Button
                        suffixIcon={<Icons.ProGalleries />}
                        onClick={() => setIsInGalleryMode(true)}
                      >
                        Manage Galleries
                      </Button>
                      <Button
                        suffixIcon={<Icons.PremiumFilled />}
                        skin={isUpgraded ? "premium-light" : "premium"}
                        as="a"
                        href={basePlatform.getUpgradeUrl()}
                        target="_blank"
                      >
                        {isUpgraded ? "Manage Plan" : "Upgrade to set live"}
                      </Button>
                    </Box>
                  }
                />
              </Box>
            }
          />
        </Card>
      </WixDesignSystemProvider>
    );
  }
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <RouterProvider router={router} />
    </WixDesignSystemProvider>
  );
}

export default App;
